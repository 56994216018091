import { Library } from '~/types/api/library'

// 1度のリクエストで取得する動画の数
export const FETCH_LIMIT = 25
export const FETCH_LIBRARY_URL = `/v2/library?limit=${FETCH_LIMIT}`

export const getLibraryFetchUrl = (library: Library | null, params: string) => {
  // 次のプレイリストを取得するには、前回取得した最後のプレイリストIDをクエリに付与する
  const addQueryParams = library
    ? `&after=${library.playlists.slice(-1)[0].id}&${params}`
    : `&${params}`

  return `${FETCH_LIBRARY_URL}${addQueryParams}`
}
