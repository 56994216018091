import { ApolloError, useQuery } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import {
  GetOriginalVideoCookieDocument,
  GetOriginalVideoCookieQuery,
} from '~/__generated__/graphql'
import { userLoginState } from '~/recoil/auth'

export const useOnClickLikeButton = (id: string, onClickLike: () => void) => {
  const loginState = useRecoilValue(userLoginState)
  const { error } = useQuery<GetOriginalVideoCookieQuery>(GetOriginalVideoCookieDocument, {
    variables: { id: id },
  })

  const unavailableReason = useMemo(() => {
    if (error) {
      return findVideoUnavailableReason(error)
    }
  }, [error])

  const { t } = useTranslation()

  const showAlert = useCallback(
    (isLike: boolean) => {
      switch (loginState) {
        case 'notSignedIn':
          alert(t(isLike ? 'login_to_like' : 'login_to_add_on_playlist'))
          break
        case 'noProfile':
          alert(t('please_create_profile'))
          break
        case 'conflict':
          alert(t('incorrect_login_method'))
          break
        case 'signedIn':
          switch (unavailableReason) {
            case 'REQUIRE_AUTH':
              alert(t('not_logged_in'))
              break
            case 'ALREADY_WITHDRAWAL':
              alert(t('already_canceled'))
              break
            case 'REQUIRE_RESUBSCRIPTION':
              alert(t('already_canceled'))
              break
            case 'REQUIRE_SUBSCRIPTION':
              alert(t(isLike ? 'login_to_like' : 'login_to_add_on_playlist'))
              break
            case 'INVALID_CHARGE':
              alert(t('invalid_payment_information'))
            default:
              break
          }
        default:
          break
      }
    },
    [t, loginState, unavailableReason],
  )

  const onClickLikeButton = useCallback(() => {
    if (loginState != 'signedIn' || unavailableReason != undefined) {
      showAlert(true)
    } else {
      onClickLike()
    }
  }, [loginState, unavailableReason, showAlert, onClickLike])

  return onClickLikeButton
}

const findVideoUnavailableReason = (error: ApolloError) => {
  const err = error.graphQLErrors.find((err) => err.extensions?.['code'] == 'VIDEO_UNAVAILABLE')
  const reason = err?.extensions?.['reason']
  return isVideoUnavailableReason(reason) ? reason : undefined
}

type VideoUnavailableReason =
  | 'REQUIRE_AUTH'
  | 'ALREADY_WITHDRAWAL'
  | 'REQUIRE_RESUBSCRIPTION'
  | 'REQUIRE_SUBSCRIPTION'
  | 'INVALID_CHARGE'

function isVideoUnavailableReason(value: any): value is VideoUnavailableReason {
  return [
    'REQUIRE_AUTH',
    'ALREADY_WITHDRAWAL',
    'REQUIRE_RESUBSCRIPTION',
    'REQUIRE_SUBSCRIPTION',
    'INVALID_CHARGE',
  ].includes(value)
}
