import { Auth } from 'aws-amplify'
import { useSession } from 'next-auth/react'
import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import useAsyncEffect from 'use-async-effect'
import { getIsAmplifyUserSignedIn } from '~/features/auth/utils/getIsAmplifyUserSignedIn'
import { accessTokenState } from '~/recoil/auth'

type InitialAccessTokenWorking = 'inprogress' | 'done'

export function useInitialAccessToken(): InitialAccessTokenWorking {
  // NOTE: useSessionでsessionが取得できる = SNSログイン
  const { data: session, status } = useSession()
  const setAccessToken = useSetRecoilState(accessTokenState)
  const [working, setWorking] = useState<InitialAccessTokenWorking>('inprogress')

  useAsyncEffect(async () => {
    if (status === 'loading') return

    // SNSログイン
    if (status === 'authenticated') {
      setAccessToken(session.access_token)
      setWorking('done')
      return
    }

    // Eメールログイン
    const isAmplifyUserSignedIn = await getIsAmplifyUserSignedIn()
    if (isAmplifyUserSignedIn) {
      try {
        const ampSession = await Auth.currentSession()
        const token = ampSession.getAccessToken().getJwtToken()
        setAccessToken(token)
      } catch (error) {
        // (想定外のケース)
        setAccessToken(undefined)
      }
    }
    setWorking('done')
  }, [session])

  return working
}
