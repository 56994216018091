import { TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { modalStyle, textFiledStyle } from './styles'

interface CreatePlayListModalProps {
  isOpen: boolean
  /** キャンセルボタン押下 */
  onCancel: () => void
  /** 保存ボタン押下 */
  onSave: (createPlayListTitle: string) => void
}

const MAX_TITLE_LENGTH = 150

export function CreatePlayListModal({
  isOpen,
  onCancel,
  onSave,
}: CreatePlayListModalProps): JSX.Element {
  const [title, setTitle] = useState('')
  const { t } = useTranslation()
  const isDisabled = title.length === 0 || title.length > MAX_TITLE_LENGTH

  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ backgroundColor: '#131313CC' }}
    >
      <Box sx={modalStyle}>
        <Typography marginBottom={10} fontSize={24} textAlign='center' fontWeight='bold'>
          {t('new_playlist')}
        </Typography>
        <Box marginBottom={8}>
          <TextField
            label={t('title')}
            variant='standard'
            fullWidth
            sx={textFiledStyle}
            size='medium'
            inputProps={{ style: { fontSize: 24 } }}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: 1.5 }}>
          <Button variant='outlined' fullWidth sx={{ borderRadius: 30 }} onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button
            variant='contained'
            fullWidth
            sx={{ borderRadius: 30 }}
            onClick={() => onSave(title)}
            disabled={isDisabled}
          >
            {t('save')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
