import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { CreatePlayListModal } from '~/features/play_list/components/PlayListActionModal/CreatePlayListModal'
import { PlayListVideoWhichNextAction } from '~/features/play_list/components/PlayListVideoWhichNextAction'
import { SelectPlayListAction } from '~/features/play_list/components/SelectPlayListAction'
import { usePlayListVideoActionController } from '~/features/play_list/hooks/usePlayListVideoActionController'
import {
  playListVideoActionUiTypeState,
  targetActionPlayListVideoInfoState,
} from '~/features/play_list/store'

export function PlayListVideoActionController(): JSX.Element {
  const [isNecessaryUpdatePage, setIsNecessaryUpdatePage] = useState(false)
  const uiType = useRecoilValue(playListVideoActionUiTypeState)
  const targetActionPlayListVideoInfo = useRecoilValue(targetActionPlayListVideoInfoState)
  const {
    onClose,
    playListInfoForView,
    startNewPlayList,
    onCreatePlayListWithAddVideo,
    onAddVideoToPlayList,
    onDeleteVideoFromPlayList,
    startAddVideoToPlayList,
    startDeletePlayListVideo,
  } = usePlayListVideoActionController()

  if (playListInfoForView === 'loading' || targetActionPlayListVideoInfo === undefined) {
    return <></>
  }

  const handleClose = () => {
    onClose(isNecessaryUpdatePage)
    setIsNecessaryUpdatePage(false)
  }

  /**
   * ・ プレリストに動画を追加する場合は動画IDを使用
   * ・ プレリストから動画を削除場合はプレイリスト動画IDを使用
   */
  const onChangePlayListVideo = (targetPlayListId: number, playListVideoId: number | undefined) => {
    if (typeof playListVideoId === 'number') {
      onDeleteVideoFromPlayList(
        targetPlayListId,
        playListVideoId,
        targetActionPlayListVideoInfo.videoId,
      )
    } else {
      onAddVideoToPlayList(targetPlayListId, targetActionPlayListVideoInfo.videoId)
    }
    setIsNecessaryUpdatePage(true)
  }

  // プレイリストの作成とその作成したプレイリストに動画を追加
  const onSavedCreatePlayListWithAddVideo = (title: string) => {
    onCreatePlayListWithAddVideo(title, targetActionPlayListVideoInfo.videoId)
  }

  // プレイリストから動画を削除
  const onStartDeletePlayListVideo = () => {
    if (
      playListInfoForView.playListId !== undefined &&
      playListInfoForView.playListVideoId !== undefined
    ) {
      startDeletePlayListVideo(
        playListInfoForView.playListId,
        playListInfoForView.playListVideoId,
        targetActionPlayListVideoInfo.videoId,
      )
    }
  }

  return (
    <>
      <SelectPlayListAction
        isOpen={uiType === 'select' || uiType === 'createPlayList'}
        onClose={handleClose}
        playListInfoForView={playListInfoForView.allPlayListInfo}
        onChangePlayListVideo={onChangePlayListVideo}
        onClickNewPlayList={startNewPlayList}
      />
      <CreatePlayListModal
        isOpen={uiType === 'createPlayList'}
        onCancel={handleClose}
        onSave={onSavedCreatePlayListWithAddVideo}
      />
      <PlayListVideoWhichNextAction
        isOpen={uiType === 'whichNextAction'}
        onClose={handleClose}
        startAddVideoToPlayList={startAddVideoToPlayList}
        startDeletePlayListVideo={onStartDeletePlayListVideo}
      />
    </>
  )
}
