import { format, parseISO, sub } from 'date-fns'
import * as DateFnsLocale from 'date-fns/locale'
import { Language } from '~/types/api/users'

function to02d(num: number): string {
  return num < 10 ? `0${num}` : `${num}`
}

export const formatDuration = (min?: number, sec?: number) =>
  `${to02d(min ?? 0)}:${to02d(sec ?? 0)}`

export const formatDurationWithUnit = (
  min?: number,
  min_unit?: string,
  sec?: number,
  sec_unit?: string,
) => `${min ?? 0}${min_unit ?? '分'} ${sec ?? 0}${sec_unit ?? '秒'}`

export const formatDate = (
  date?: string | null,
  locale?: Language,
  withWeekday: boolean = false,
) => {
  if (date == null || locale === undefined) return undefined

  const parsedDate = parseISO(date)
  switch (locale) {
    case 'ja':
      return format(parsedDate, withWeekday ? 'yyyy年M月d日（E）' : 'yyyy年M月d日', {
        locale: DateFnsLocale.ja,
      })
    case 'en':
      return format(parsedDate, withWeekday ? 'EEEE, MMMM d, yyyy' : 'MMMM d, yyyy', {
        locale: DateFnsLocale.enUS,
      })
    case 'ko':
      return format(parsedDate, withWeekday ? 'yyyy년 MM월 dd일 (E)' : 'yyyy년 MM월 dd일', {
        locale: DateFnsLocale.ko,
      })
    case 'th':
      return format(parsedDate, withWeekday ? 'd MMMM yyyy (E)' : 'd MMMM yyyy', {
        locale: DateFnsLocale.th,
      })
    case 'id':
      return format(parsedDate, withWeekday ? 'd MMMM yyyy (E)' : 'd MMMM yyyy', {
        locale: DateFnsLocale.id,
      })
    default: // 例外のケース
      return undefined
  }
}

export const formatPostedDate = (postedAt?: string) => {
  if (postedAt == undefined) return ''
  const dateTime = parseISO(postedAt)
  const now = new Date()
  const diff = Math.abs(now.getTime() - dateTime.getTime())
  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(diff / (1000 * 60))
  const hours = Math.floor(diff / (1000 * 60 * 60))
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))

  if (seconds < 60) {
    return `${seconds} seconds ago`
  } else if (minutes < 60) {
    return `${minutes} minutes ago`
  } else if (hours < 24) {
    return `${hours} hours ago`
  } else if (days < 30) {
    return `${days} days ago`
  } else if (days < 365) {
    const months = Math.floor(days / 30)
    return `${months} months ago`
  } else {
    const years = Math.floor(days / 365)
    return `${years} year ago`
  }
}

export const format1dayBeforeDate = (dateStr?: string) => {
  if (dateStr == undefined) return
  const date = parseISO(dateStr)
  const dateBefore1day = sub(date, { days: 1 })
  return format(dateBefore1day, 'yyyy-MM-dd')
}
