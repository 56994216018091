import { CopyAll, Facebook } from '@mui/icons-material'
import { SvgAsset } from '~/components/SvgAsset'
import { ShareItemType } from './hook'

type ShareItemsInfo = {
  [type in ShareItemType]: {
    i18nKey: 'copy_url' | 'share_by_x' | 'share_by_facebook'
    iconComponent: JSX.Element
  }
}

export const SHARE_ITEMS_INFO: ShareItemsInfo = {
  url: {
    i18nKey: 'copy_url',
    iconComponent: <CopyAll />,
  },
  x: {
    i18nKey: 'share_by_x',
    iconComponent: <SvgAsset asset='x' />,
  },
  facebook: {
    i18nKey: 'share_by_facebook',
    iconComponent: <Facebook />,
  },
}
