import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { Box, Grid, IconButton, SvgIcon } from '@mui/material'
import AppStoreBadgeIcon from '~/assets/app_store_badge.svg'
import GooglePlayBadgeIcon from '~/assets/google_play_badge.svg'
import { URLConstants } from '~/libs/constants'
import { Colors } from '~/libs/mui/theme'

const SOCIAL_MEDIA_INFO = {
  facebook: { icon: FacebookIcon, href: URLConstants.facebookPage },
  x: { icon: XIcon, href: URLConstants.xPage },
  instagram: { icon: InstagramIcon, href: URLConstants.instagramPage },
  youtube: { icon: YouTubeIcon, href: URLConstants.youtubePage },
}

export function AppFooterRightContent(): JSX.Element {
  return (
    <Box display='flex'>
      {/* SNSアイコン */}
      <Box sx={{ color: Colors.color5A5957 }}>
        <Grid container>
          <SocialIcon type='facebook' />
          <SocialIcon type='x' />
        </Grid>
        <Grid container>
          <SocialIcon type='instagram' />
          <SocialIcon type='youtube' />
        </Grid>
      </Box>
      {/* アプリストア */}
      <Box display='flex' flexDirection='column'>
        <IconButton href={URLConstants.googlePlayPage} target='_blank'>
          <GooglePlayBadgeIcon />
        </IconButton>
        <IconButton href={URLConstants.appStorePage} target='_blank'>
          <AppStoreBadgeIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

interface SocialIconProps {
  type: 'facebook' | 'x' | 'instagram' | 'youtube'
}

function SocialIcon({ type }: SocialIconProps): JSX.Element {
  const { icon, href } = SOCIAL_MEDIA_INFO[type]
  return (
    <IconButton color='inherit' href={href} target='_blank'>
      <SvgIcon component={icon} sx={{ fontSize: 32 }} />
    </IconButton>
  )
}
