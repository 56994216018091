import { Dialog, List, Snackbar } from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ShareListItem } from './ShareListItem'
import { ShareItemType, useShareModalViewModal } from './hook'

interface ShareModalProps {
  open: boolean
  onClose: () => void
  videoId?: number
}

export function ShareModal({ open, onClose, videoId }: ShareModalProps): JSX.Element {
  const { t } = useTranslation()
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const { onClickShareItem } = useShareModalViewModal(videoId)

  // NOTE: URLコピーの場合のみ、スナックバーを表示する
  const handleClickShareItem = useCallback(
    (type: ShareItemType) => {
      if (type === 'url') {
        setOpenSnackbar(true)
      }
      onClickShareItem(type)
      onClose()
    },
    [onClose, onClickShareItem],
  )

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <List>
          <ShareListItem type='url' onClickShareItem={() => handleClickShareItem('url')} />
          <ShareListItem type='x' onClickShareItem={() => onClickShareItem('x')} />
          <ShareListItem type='facebook' onClickShareItem={() => onClickShareItem('facebook')} />
        </List>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={t('coy_to_clipboard')}
      />
    </>
  )
}
