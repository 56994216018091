import { Box, Link as MuiLink, Typography } from '@mui/material'
import NextLink from 'next/link'
import { ComponentProps } from 'react'
import { Trans } from 'react-i18next'
import { URLConstants } from '~/libs/constants'
import { Colors } from '~/libs/mui/theme'

export function AppFooterLeftContent(): JSX.Element {
  return (
    <Box display='flex' gap={3}>
      <Box display='flex' flexDirection='column' gap='4px'>
        {/* お問い合わせ */}
        <LinkText href={'/setting/contact'}>
          <Trans i18nKey='contact' />
        </LinkText>
        {/* 運営会社 */}
        <LinkText href={URLConstants.homepage} target='_blank'>
          <Trans i18nKey='about' />
        </LinkText>
        {/* クリエイターはこちら */}
        <LinkText href='/filmmakers'>
          <Trans i18nKey='to_all_creators' />
        </LinkText>
      </Box>
      <Box display='flex' flexDirection='column' gap='4px'>
        {/* プライパシーポリシー */}
        <LinkText href={URLConstants.policy} target='_blank'>
          <Trans i18nKey='privacy_policy' />
        </LinkText>
        {/* 利用規約 */}
        <LinkText href={URLConstants.terms} target='_blank'>
          <Trans i18nKey='terms' />
        </LinkText>
      </Box>
    </Box>
  )
}

function LinkText({ children, ...props }: ComponentProps<typeof MuiLink>): JSX.Element {
  return (
    <MuiLink component={NextLink} underline='none' {...props}>
      <Box display='inline-flex' alignItems='center' gap='5px'>
        <Box
          width='5px'
          height='5px'
          borderRadius='50%'
          sx={{ backgroundColor: Colors.color373737 }}
        />
        <Typography sx={{ color: Colors.color5A5957 }}>{children}</Typography>
      </Box>
    </MuiLink>
  )
}
