import { Drawer, Hidden, Popover } from '@mui/material'
import { useContext } from 'react'
import { ActionStartingPointContext } from '../PlayListPopOverStartingPointProvider/context'
import { PlayListVideoWhichNextActionContent } from './PlayListVideoWhichNextActionContent'
import { PlayListVideoWhichNextActionProps } from './type'

export function PlayListVideoWhichNextAction({
  isOpen,
  onClose,
  startAddVideoToPlayList,
  startDeletePlayListVideo,
}: PlayListVideoWhichNextActionProps): JSX.Element {
  const { startPointElement } = useContext(ActionStartingPointContext)

  return (
    <>
      <Hidden smUp>
        <Drawer anchor='bottom' open={isOpen} onClose={onClose}>
          <PlayListVideoWhichNextActionContent
            onClose={onClose}
            startAddVideoToPlayList={startAddVideoToPlayList}
            startDeletePlayListVideo={startDeletePlayListVideo}
          />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Popover
          open={isOpen}
          anchorEl={startPointElement}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <PlayListVideoWhichNextActionContent
            onClose={onClose}
            startAddVideoToPlayList={startAddVideoToPlayList}
            startDeletePlayListVideo={startDeletePlayListVideo}
          />
        </Popover>
      </Hidden>
    </>
  )
}
