import { isAfter, isSameDay, parseISO } from 'date-fns'
import { PaymentType } from './payments'

export type Gender = 'neither' | 'male' | 'female'

export type UserStatus = 'free_user' | 'withdrawal_user' | 'subscription_user'

export type Language = 'ja' | 'en' | 'ko' | 'th' | 'id'

export interface Profile {
  id?: number
  avatar_url?: string
  date_of_birth?: string | null
  email: string
  provider?: string
  mail_desired: boolean
  gender?: Gender
  language: Language | null
  name: string
  country?: string
  invalid_charge?: boolean
  subscription_end_date?: string
  next_payment_date?: string
  user_status?: UserStatus
  free_trial_end_date?: string
  payment_type?: PaymentType
  autoplay_videos?: boolean
}

export const profileIsDuringFreeTrial = (profile: Profile) => {
  if (profile.free_trial_end_date == undefined) return false
  const now = new Date()
  const date = parseISO(profile.free_trial_end_date)
  return isAfter(date, now) || isSameDay(date, now)
}

export enum LoginProvider {
  email,
  apple,
  google,
  twitter,
}

export const loginProvider = (provider?: string): LoginProvider => {
  const providerName = provider?.toLowerCase()
  if (providerName?.includes('google')) {
    return LoginProvider.google
  } else if (providerName?.includes('apple')) {
    return LoginProvider.apple
  } else if (providerName?.includes('twitter')) {
    return LoginProvider.twitter
  } else {
    return LoginProvider.email
  }
}
