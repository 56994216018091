export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  paddingX: { xs: 4, sm: 2 },
  outline: 'none',
  maxWidth: { xs: 500, sm: 800 },
  textAlign: 'center',
}

export const textFiledStyle = {
  width: 1,
  '& label': {
    // ラベルタイトル
    fontSize: { xs: 14, sm: 24 },
    color: '#ffffff',
  },
  '& .MuiInput-underline:before': {
    // 通常時のボーダー色
    borderBottomColor: '#ffffff',
  },
  '& .MuiFormLabel-root': {
    //ラベルタイトル
    fontSize: { xs: 14, sm: 22 },
  },
}
