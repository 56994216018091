import { createContext, useContext } from 'react'

type Device = 'iOS' | 'Android' | 'PC'
export const DeviceContext = createContext<Device>('PC')

export const useDevice = () => useContext(DeviceContext)

export const getDevice = (userAgent: string): Device => {
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'iOS'
  }

  if (/Android/i.test(userAgent)) {
    return 'Android'
  }

  return 'PC'
}
