import type { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useSWRConfig } from 'swr'
import { useAddLocaleParams } from '~/features/i18n/hooks/useAddLocaleParams'
import {
  FETCH_LIBRARY_URL,
  getLibraryFetchUrl,
} from '~/features/play_list/hooks/useGetPlayListLibraryViewModel/url'
import { useAxios } from '~/libs/axios'
import {
  CreatePlayListArgs,
  CreatePlayListResponse,
  EditPlayListArgs,
  EditPlayListResponse,
} from './type'

// useSWRInfiniteを使用して取得したAPIの場合、APIのURLに$inf$がプレフィックスとして付与される
const INFINITE_PREFIX = '$inf$'
const mutateUrl = `${INFINITE_PREFIX}${FETCH_LIBRARY_URL}`

interface PlayListActionApi {
  createPlayListApi: (title: string) => Promise<CreatePlayListResponse>
  editPlayListApi: (id: number, title: string) => Promise<void>
  deletePlayListApi: (id: number) => Promise<void>
}

export function usePlayListActionApi(): PlayListActionApi {
  const axios = useAxios()
  const { mutate } = useSWRConfig()
  const localeParams = useAddLocaleParams()

  const revalidateApiForPageUpdate = useCallback(async () => {
    // NOTE: 一番初めのfetchURLを再検証することで、後続でfetchしたURLも再検証される
    const mutateUrl = `${INFINITE_PREFIX}${getLibraryFetchUrl(null, localeParams)}`
    await mutate(mutateUrl)
  }, [mutate, localeParams])

  const createPlayListApi = useCallback(
    async (title: string) => {
      const res = await axios.post<
        CreatePlayListResponse,
        AxiosResponse<CreatePlayListResponse>,
        CreatePlayListArgs
      >(`/v2/playlists`, {
        playlist: {
          title,
          row_order_position: 0,
        },
      })
      await revalidateApiForPageUpdate()
      return res.data
    },
    [axios, revalidateApiForPageUpdate],
  )

  const editPlayListApi = useCallback(
    async (id: number, title: string) => {
      await axios.put<EditPlayListResponse, AxiosResponse<EditPlayListResponse>, EditPlayListArgs>(
        `/v2/playlists/${id}`,
        {
          playlist: {
            title,
          },
        },
      )
      await revalidateApiForPageUpdate()
    },
    [axios, revalidateApiForPageUpdate],
  )

  const deletePlayListApi = useCallback(
    async (id: number) => {
      await axios.delete(`/v2/playlists/${id}`)
      await revalidateApiForPageUpdate()
    },
    [axios, revalidateApiForPageUpdate],
  )

  return { createPlayListApi, editPlayListApi, deletePlayListApi }
}
