import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SvgAsset } from '~/components/SvgAsset'
import { NAVIGATION_PATHS, PAGE_PATH } from './constant'

export function AppBottomNavigation(): JSX.Element {
  const { push } = useRouter()
  const pathname = usePathname()
  const { t } = useTranslation()

  const [navigationPath, setNavigationPath] = useState('other')

  useEffect(() => {
    if (PAGE_PATH.includes(pathname || '')) {
      setNavigationPath(pathname || '')
    } else {
      setNavigationPath('other')
    }
  }, [pathname])

  return (
    <BottomNavigation showLabels value={navigationPath} sx={{ backgroundColor: 'black' }}>
      {NAVIGATION_PATHS.map(({ labelNameI18nKey, pagePath, iconAssetsName }, idx) => (
        <BottomNavigationAction
          key={idx}
          sx={{
            padding: '0',
          }}
          onClick={() => push(pagePath)}
          label={<span style={{ fontSize: 12 }}>{t(labelNameI18nKey)}</span>}
          value={pagePath}
          icon={<SvgAsset asset={iconAssetsName} />}
        />
      ))}
    </BottomNavigation>
  )
}
