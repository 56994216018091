import * as Apollo from '@apollo/client'

export interface CacheEdges<T> {
  nodes: { [cursor: string]: T }
  cursors: string[]
  ids: string[]
}

export interface CacheNodes<T> {
  nodes: { [id: string]: T }
  ids: string[]
}

export const cursorConnection = <Edge, Node>(): Apollo.TypePolicy => ({
  fields: {
    edges: {
      merge(
        existing: CacheEdges<Edge> | undefined = {
          nodes: {},
          cursors: [],
          ids: [],
        },
        incoming: any[],
        options,
      ) {
        const nodes = Object.assign({}, existing.nodes)
        const cursors = Object.assign([], existing.cursors)
        const ids = Object.assign([], existing.ids)
        incoming.forEach((item) => {
          const cursor = options.readField('cursor', item) as string
          const node = options.readField('node', item) as any
          if (!existing.cursors.includes(cursor)) {
            cursors.push(cursor)
            ids.push(options.readField('id', node)!)
            nodes[cursor] = item
          }
        })
        return { nodes, cursors, ids }
      },
      read(
        existing: CacheEdges<Edge> | undefined = {
          nodes: {},
          cursors: [],
          ids: [],
        },
      ) {
        return existing.cursors.map((cursor) => existing.nodes[cursor])
      },
    },
    nodes: {
      merge(
        // idsで順序を保証してからmapする
        existing: CacheNodes<Node> | undefined = {
          nodes: {},
          ids: [],
        },
        incoming: any[],
        options,
      ) {
        const nodes = Object.assign({}, existing.nodes)
        const ids = Object.assign([], existing.ids)
        incoming.forEach((item) => {
          const id = options.readField('id', item) as string
          if (!existing.ids.includes(id)) {
            ids.push(id)
            nodes[id] = item
          }
        })
        return { nodes, ids }
      },
      read(
        existing: CacheNodes<Node> | undefined = {
          nodes: {},
          ids: [],
        },
      ) {
        return existing.ids.map((id) => existing.nodes[id])
      },
    },
  },
})
