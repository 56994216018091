import { atom } from 'recoil'

export type PlayListActionUiType = 'create' | 'edit' | 'delete' | 'whichNextAction' | 'standby'
export type PlayListVideoActionUiType = 'select' | 'whichNextAction' | 'createPlayList' | 'standby'

export const playListActionUiTypeState = atom<PlayListActionUiType>({
  key: 'play_list_action_ui_type',
  default: 'standby',
})

export const playListVideoActionUiTypeState = atom<PlayListVideoActionUiType>({
  key: 'play_list_video_action_ui_type',
  default: 'standby',
})

// プレイリストの編集・削除の対象となるプレイリストの情報
export interface TargetActionPlayListInfo {
  id: number
  title: string
  thumbnailUrl?: string
}

export const targetActionPlayListInfoState = atom<TargetActionPlayListInfo | undefined>({
  key: 'target_action_play_list_info_state',
  default: undefined,
})

// プレイリストに登録されている動画の編集・削除の対象となるプレイリストの情報
export interface TargetActionPlayListVideoInfo {
  videoId: number
}

export const targetActionPlayListVideoInfoState = atom<TargetActionPlayListVideoInfo | undefined>({
  key: 'target_action_play_list_video_info_state',
  default: undefined,
})
