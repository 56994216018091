import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { t } from 'i18next'
import { SHARE_ITEMS_INFO } from './constants'
import { ShareItemType } from './hook'

interface ShareListItemProps {
  type: ShareItemType
  onClickShareItem: () => void
}

export function ShareListItem({ type, onClickShareItem }: ShareListItemProps): JSX.Element {
  const { i18nKey, iconComponent } = SHARE_ITEMS_INFO[type]

  return (
    <ListItem onClick={onClickShareItem}>
      <ListItemButton>
        <ListItemIcon>{iconComponent}</ListItemIcon>
        <ListItemText primary={t(i18nKey)} />
      </ListItemButton>
    </ListItem>
  )
}
