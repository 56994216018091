import { useParams, usePathname } from 'next/navigation'
import { useCallback, useMemo } from 'react'
import { useSetRecoilState } from 'recoil'
import { playListVideoActionUiTypeState } from '~/features/play_list/store'
import { usePlayListActionApi } from './usePlayListActionApi'
import { usePlayListRegistrationInfo } from './usePlayListRegistrationInfo'
import { usePlayListVideoActionApi } from './usePlayListVideoActionApi'

export interface PlayListVideoActionController {
  onClose: (isNecessaryUpdatePage: boolean) => void
  onAddVideoToPlayList: (targetPlayListId: number, videoId: number) => void
  onDeleteVideoFromPlayList: (
    targetPlayListId: number,
    playlistVideoId: number,
    videoId: number,
  ) => void
  onCreatePlayListWithAddVideo: (title: string, videoId: number) => void
  startDeletePlayListVideo: (
    targetPlayListId: number,
    playlistVideoId: number,
    videoId: number,
  ) => void
  startNewPlayList: () => void
  startAddVideoToPlayList: () => void
  playListInfoForView: ReturnType<typeof usePlayListRegistrationInfo>
}

export function usePlayListVideoActionController(): PlayListVideoActionController {
  const pathname = usePathname()
  const params = useParams()
  const queryPlayListId = params ? Number(params.id) : undefined
  const setPlaylistVideoActionUiType = useSetRecoilState(playListVideoActionUiTypeState)
  const { addVideoToPlayListApi, deleteVideoFromPlayListApi, revalidateApiForPageUpdate } =
    usePlayListVideoActionApi()
  const { createPlayListApi } = usePlayListActionApi()
  const playListInfoForView = usePlayListRegistrationInfo()

  const updatePageAfterApiAction = useCallback(() => {
    // プレイリスト詳細ページ(/library/:id)では、アクションUIをクローズした後にアクションで変更された内容を反映する必要がある
    if (typeof queryPlayListId === 'number' && pathname?.includes('/library')) {
      revalidateApiForPageUpdate(queryPlayListId)
    }
  }, [queryPlayListId, pathname, revalidateApiForPageUpdate])

  const onClose = useCallback(
    (isNecessaryUpdatePage: boolean) => {
      if (isNecessaryUpdatePage) {
        updatePageAfterApiAction()
      }
      setPlaylistVideoActionUiType('standby')
    },
    [setPlaylistVideoActionUiType, updatePageAfterApiAction],
  )

  const onAddVideoToPlayList = useCallback(
    (targetPlayListId: number, videoId: number) => {
      addVideoToPlayListApi(targetPlayListId, videoId)
    },
    [addVideoToPlayListApi],
  )

  const onDeleteVideoFromPlayList = useCallback(
    (targetPlayListId: number, playlistVideoId: number, videoId: number) => {
      deleteVideoFromPlayListApi(targetPlayListId, playlistVideoId, videoId)
    },
    [deleteVideoFromPlayListApi],
  )

  const onCreatePlayListWithAddVideo = useCallback(
    async (title: string, videoId: number) => {
      await createPlayListApi(title).then((createdPlaylist) =>
        addVideoToPlayListApi(createdPlaylist.playlist.id, videoId),
      )
      setPlaylistVideoActionUiType('standby')
    },
    [createPlayListApi, addVideoToPlayListApi, setPlaylistVideoActionUiType],
  )

  const startDeletePlayListVideo = useCallback(
    (targetPlayListId: number, playlistVideoId: number, videoId: number) => {
      deleteVideoFromPlayListApi(targetPlayListId, playlistVideoId, videoId).then(() => {
        updatePageAfterApiAction()
      })
      setPlaylistVideoActionUiType('standby')
    },
    [deleteVideoFromPlayListApi, , updatePageAfterApiAction, setPlaylistVideoActionUiType],
  )

  const startNewPlayList = useCallback(() => {
    setPlaylistVideoActionUiType('createPlayList')
  }, [setPlaylistVideoActionUiType])

  const startAddVideoToPlayList = useCallback(() => {
    setPlaylistVideoActionUiType('select')
  }, [setPlaylistVideoActionUiType])

  return useMemo(
    () => ({
      onClose,
      onAddVideoToPlayList,
      onDeleteVideoFromPlayList,
      onCreatePlayListWithAddVideo,
      startNewPlayList,
      startAddVideoToPlayList,
      startDeletePlayListVideo,
      playListInfoForView,
    }),
    [
      onClose,
      onAddVideoToPlayList,
      onDeleteVideoFromPlayList,
      onCreatePlayListWithAddVideo,
      startNewPlayList,
      startAddVideoToPlayList,
      startDeletePlayListVideo,
      playListInfoForView,
    ],
  )
}
