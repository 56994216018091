import { Box, Drawer, Hidden, Modal } from '@mui/material'
import { SelectPlayListActionContent } from './SelectPlayListActionContent'
import { SelectPlayListActionProps } from './type'

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 350,
  paddingX: 2,
  outline: 'none',
  borderRadius: '0 6px 0 6px',
}

export function SelectPlayListAction({ isOpen, ...props }: SelectPlayListActionProps): JSX.Element {
  return (
    <>
      <Hidden smUp>
        <Drawer anchor='bottom' open={isOpen}>
          <SelectPlayListActionContent {...props} />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Modal
          open={isOpen}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={modalStyle}>
            <Box role='presentation'>
              <SelectPlayListActionContent {...props} />
            </Box>
          </Box>
        </Modal>
      </Hidden>
    </>
  )
}
