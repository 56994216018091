import AddIcon from '@mui/icons-material/Add'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Typography,
  styled,
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { SelectPlayListActionProps } from './type'

const CloseArea = styled(Box)(() => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}))

type SelectPlayListActionContentProps = Pick<
  SelectPlayListActionProps,
  'onChangePlayListVideo' | 'onClickNewPlayList' | 'onClose' | 'playListInfoForView'
>

export function SelectPlayListActionContent({
  playListInfoForView,
  onChangePlayListVideo,
  onClickNewPlayList,
  onClose,
}: SelectPlayListActionContentProps): JSX.Element {
  const { t } = useTranslation()
  return (
    <Box role='presentation' sx={{ backgroundColor: '#131313', borderRadius: 1 }}>
      <Box sx={{ paddingX: 2, paddingTop: 4, paddingBottom: 2 }}>
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          sx={{ borderRadius: 30 }}
          onClick={onClickNewPlayList}
        >
          {t('create_a_new_playlist')}
        </Button>
        <List>
          {playListInfoForView.map(
            ({ playListId, playListVideoId, title, isRegisteredPlayList }, idx) => (
              <ListItem disablePadding sx={{ paddingLeft: 2, paddingY: 1 }} key={idx}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckCircleIcon />}
                      checkedIcon={<CheckCircleOutlineIcon />}
                      checked={isRegisteredPlayList}
                    />
                  }
                  label={title}
                  sx={{ width: 1 }}
                  onChange={() => onChangePlayListVideo(playListId, playListVideoId)}
                />
              </ListItem>
            ),
          )}
        </List>
      </Box>
      <Divider />
      <CloseArea paddingTop={2.5} paddingBottom={3} textAlign='center' onClick={onClose}>
        <Typography>{t('close')}</Typography>
      </CloseArea>
    </Box>
  )
}
