import { Auth } from 'aws-amplify'

export const getIsAmplifyUserSignedIn = async () => {
  try {
    await Auth.currentAuthenticatedUser()
    return true
  } catch (_error) {
    return false
  }
}
