import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import useSWR from 'swr'
import { useAddLocaleParams } from '~/features/i18n/hooks/useAddLocaleParams'
import { myLanguageState } from '~/features/i18n/store'
import { useAxios } from '~/libs/axios'
import { formatDurationWithUnit, formatDate } from '~/libs/format'
import { previewVideoState } from '~/recoil/preview'
import { VideoPreview, VideoStatus, VideoSubtitle } from '~/types/api/videos'

export interface VideoPreviewModalInfo {
  title: string
  videoId: number
  duration: string
  videoUrl?: string
  subtitles: VideoSubtitle[]
  vvts: VideoSubtitle[]
  description: string
  creator: {
    name: string
    avatarUrl?: string
  }
  status: VideoStatus
  likeNum: number
  willBePublishedAt: string
}

export function useVideoPreviewModalInfo(): 'loading' | VideoPreviewModalInfo {
  const axios = useAxios()
  const localeParams = useAddLocaleParams()
  const previewVideoId = useRecoilValue(previewVideoState)
  const myLanguage = useRecoilValue(myLanguageState)
  const { data: previewVideo } = useSWR<VideoPreview>(
    previewVideoId !== undefined ? `/v2/videos/${previewVideoId}/preview?${localeParams}` : null,
    (args) => axios.get(args).then((res) => res.data),
  )
  return useMemo(() => {
    if (previewVideo === undefined) return 'loading'
    return {
      title: previewVideo.original.title || '',
      videoId: previewVideo.original.id,
      duration: formatDurationWithUnit(
        previewVideo.original.duration_min,
        previewVideo.original.duration_min_unit_name,
        previewVideo.original.duration_second,
        previewVideo.original.duration_second_unit_name,
      ),
      videoUrl: previewVideo.trailer?.file_url,
      subtitles: previewVideo.trailer?.subtitles || [],
      vvts: previewVideo.trailer?.vtts || [],
      description: previewVideo.original.description || previewVideo.trailer?.description || '',
      creator: {
        name: previewVideo.original.creator?.name || '',
        avatarUrl: previewVideo.original.creator?.avatar_url,
      },
      status: previewVideo.original.status || 'unpublished',
      likeNum: previewVideo.original.like_num ?? 0,
      willBePublishedAt:
        formatDate(previewVideo.original.will_be_published_at, myLanguage, true) || '',
    }
  }, [previewVideo])
}
