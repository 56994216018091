import { useQuery } from '@apollo/client'
import { useParams } from 'next/navigation'
import { useCallback, useMemo } from 'react'
import { GetOriginalVideoDocument, GetOriginalVideoQuery } from '~/__generated__/graphql'
import browserEnv from '~/libs/env/browserEnv'
import * as GA from './ga'

export type ShareItemType = 'url' | 'x' | 'facebook'

interface ShareModalViewModal {
  onClickShareItem: (itemType: ShareItemType) => void
}

export function useShareModalViewModal(previewVideoId?: number): ShareModalViewModal {
  const params = useParams()
  const videoId = params ? Number(params.id) : undefined
  const handleVideoId = !!previewVideoId ? previewVideoId : videoId
  const res = useQuery<GetOriginalVideoQuery>(GetOriginalVideoDocument, {
    variables: { id: handleVideoId },
  })
  const videoInfo = useMemo(() => {
    if (handleVideoId === undefined) return null
    return res.data
  }, [handleVideoId, res.data])

  const onClickShareItem = useCallback(
    (item: ShareItemType) => {
      // videoInfoとvideoIdが取得できないケースは例外
      if (videoInfo == null || handleVideoId === undefined) {
        return
      }
      GA.shareItemClick(handleVideoId)
      const url = `${browserEnv.NEXT_PUBLIC_HOST}/videos/${handleVideoId}`
      const title = videoInfo.originalVideo?.title
      const description = videoInfo.originalVideo?.description?.substring(0, 40)
      switch (item) {
        case 'url':
          console.log
          navigator.clipboard.writeText(url)
          break
        case 'x':
          window.open(
            `https://x.com/intent/tweet?url=${url}%0a&text=${title}%0a${description}%0a&hashtags=samansa`,
            '_blank',
          )
          break
        case 'facebook':
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank')
          break
      }
    },
    [handleVideoId, videoInfo],
  )

  return { onClickShareItem }
}
