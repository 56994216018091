import { PlayArrow } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { ReactNode, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SvgAsset } from '~/components/SvgAsset'
import { Colors } from '~/libs/mui/theme'
import { ShareModal } from '../../ShareModal'

interface VideoButtonContentProps {
  onClickVideoPlay: () => void
  onClickAddPlaylist: () => void
  /** 近日公開予定動画であるかどうか */
  isUpComing: boolean
  videoId?: number
}

function IconButon({ children, onClick }: { children: ReactNode; onClick: () => void }) {
  return (
    <Button
      sx={{
        flexGrow: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0,
        minWidth: '48px',
        width: '48px',
        height: '48px',
        gap: '4px',
        color: Colors.color5A5957,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export function VideoButtonContent({
  onClickVideoPlay,
  onClickAddPlaylist,
  isUpComing,
  videoId,
}: VideoButtonContentProps): JSX.Element {
  const [openShareModal, setOpenShareModal] = useState(false)
  const { t } = useTranslation()

  return (
    <Box display='flex' gap={2}>
      {!isUpComing && (
        <Button
          variant='contained'
          endIcon={<PlayArrow />}
          sx={{ justifyContent: 'space-between', flexGrow: 1 }}
          onClick={onClickVideoPlay}
        >
          PLAY
        </Button>
      )}
      {isUpComing ? (
        <Button
          variant='contained'
          endIcon={<PlayArrow />}
          sx={{ justifyContent: 'space-between', flexGrow: 1 }}
          onClick={onClickAddPlaylist}
        >
          {t('add_to_playlist')}
        </Button>
      ) : (
        <IconButon onClick={onClickAddPlaylist}>
          <SvgAsset asset='add_playlist' sx={{ width: 32, height: 32 }} />
          <Typography fontSize={12} lineHeight={1} fontWeight={500}>
            <Trans i18nKey='add' />
          </Typography>
        </IconButon>
      )}
      <IconButon onClick={() => setOpenShareModal(true)}>
        <SvgAsset asset='share' sx={{ width: 32, height: 32, fill: undefined }} />
        <Typography fontSize={12} lineHeight={1} fontWeight={500}>
          <Trans i18nKey='share' />
        </Typography>
      </IconButon>
      <ShareModal
        open={openShareModal}
        onClose={() => setOpenShareModal(false)}
        videoId={videoId}
      />
    </Box>
  )
}
