import type { Route } from 'next'
import { SvgAssetType } from '~/components/SvgAsset'

export const PAGE_PATH: string[] = ['/search', '/', '/library', '/profile']

interface NavigationPathInfo {
  labelNameI18nKey: 'home' | 'search' | 'playlist' | 'profile'
  pagePath: Route
  iconAssetsName: SvgAssetType
}

export const NAVIGATION_PATHS: NavigationPathInfo[] = [
  {
    labelNameI18nKey: 'home',
    pagePath: '/',
    iconAssetsName: 'home',
  },
  {
    labelNameI18nKey: 'search',
    pagePath: '/search',
    iconAssetsName: 'search',
  },
  {
    labelNameI18nKey: 'playlist',
    pagePath: '/library',
    iconAssetsName: 'playlist',
  },
  {
    labelNameI18nKey: 'profile',
    pagePath: '/profile',
    iconAssetsName: 'user',
  },
]
