import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useSWR from 'swr'
import { useAddLocaleParams } from '~/features/i18n/hooks/useAddLocaleParams'
import { useAxios } from '~/libs/axios'
import { availableAuthApiState } from '~/recoil/auth'
import { Profile } from '~/types/api/users'
import { autoPlayState } from '../store'

export function useAutoPlaySetting() {
  const axios = useAxios()
  const [isSetupCompleted, setIsSetupCompleted] = useState(false)
  const availableAuthApi = useRecoilValue(availableAuthApiState)
  const localeParams = useAddLocaleParams()
  const { data: profile, isLoading } = useSWR<Profile>(
    availableAuthApi ? `/v2/user/edit?${localeParams}` : null,
    (args) => axios.get(args).then((res) => res.data),
  )

  const setAutoPlayState = useSetRecoilState(autoPlayState)

  useEffect(() => {
    if (isLoading) return

    const autoPlay = profile !== undefined ? profile.autoplay_videos : undefined

    // プロフィールの自動再生設定を反映
    setAutoPlayState(autoPlay)
    setIsSetupCompleted(true)
  }, [isLoading, profile, setAutoPlayState])

  return isSetupCompleted
}
