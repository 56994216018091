import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Colors } from '~/libs/mui/theme'
import { VideoInfo } from '../../VideoInfo/VideoInfo'

interface VideoDetailContentProps {
  id: number
  title: string
  duration: string
  description: string
  creator: {
    name: string
    avatarUrl?: string
  }
  isUpComing: boolean
  willBePublishedAt: string
}

export function VideoDetailContent({
  id,
  title,
  duration,
  description,
  creator,
  isUpComing,
  willBePublishedAt,
}: VideoDetailContentProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <Stack spacing={2}>
      <Typography id='video-preview-title' variant='h2' color={Colors.yellow}>
        {title}
      </Typography>
      {isUpComing && (
        <Typography mt={1} color={'white'}>
          {willBePublishedAt} {t('upcoming_release')}
        </Typography>
      )}
      <VideoInfo
        id={id}
        creator={{
          name: creator.name,
          avatarUrl: creator.avatarUrl,
        }}
        duration={duration}
        description={description}
        showLike={!isUpComing}
      />
    </Stack>
  )
}
