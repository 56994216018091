import type { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useSWRConfig } from 'swr'
import { useAddLocaleParams } from '~/features/i18n/hooks/useAddLocaleParams'
import { getPlayListFetchUrl } from '~/features/play_list/hooks/useGetPlayListVideoViewModel/urls'
import {
  getFetchPlaylistsUrl,
  getVideoPreviewInfoFetchUrl,
} from '~/features/play_list/hooks/usePlayListRegistrationInfo/urls'
import { useAxios } from '~/libs/axios'
import { AddVideoToPlayListArgs, AddVideoToPlayListResponse } from './type'

// useSWRInfiniteを使用して取得したAPIの場合、APIのURLに$inf$がプレフィックスとして付与される
const INFINITE_PREFIX = '$inf$'

interface PlayListVideoActionApi {
  addVideoToPlayListApi: (playListId: number, videoId: number) => Promise<void>
  deleteVideoFromPlayListApi: (
    playListId: number,
    playlistVideoId: number,
    videoId: number,
  ) => Promise<void>
  revalidateApiForPageUpdate: (playListId: number) => void
}

export function usePlayListVideoActionApi(): PlayListVideoActionApi {
  const axios = useAxios()
  const { mutate } = useSWRConfig()
  const localeParams = useAddLocaleParams()

  const revalidateApiForSelectPlayList = useCallback(
    (videoId: number) => {
      const mutates = [
        mutate(getFetchPlaylistsUrl(localeParams)),
        mutate(getVideoPreviewInfoFetchUrl(videoId, localeParams)),
      ]
      Promise.all(mutates)
    },
    [mutate, localeParams],
  )

  const revalidateApiForPageUpdate = useCallback(
    (playListId: number) => {
      // NOTE: 一番初めのfetchURLを再検証することで、後続でfetchしたURLも再検証される
      const mutateUrl = `${INFINITE_PREFIX}${getPlayListFetchUrl(playListId, null, localeParams)}`
      mutate(mutateUrl)
    },
    [mutate, localeParams],
  )

  const addVideoToPlayListApi = useCallback(
    async (playListId: number, videoId: number) => {
      await axios.post<
        AddVideoToPlayListResponse,
        AxiosResponse<AddVideoToPlayListResponse>,
        AddVideoToPlayListArgs
      >(`/v2/playlists/${playListId}/videos`, {
        playlists_video: {
          video_id: videoId,
          row_order_position: 0,
        },
      })
      revalidateApiForSelectPlayList(videoId)
    },
    [axios, revalidateApiForSelectPlayList],
  )

  const deleteVideoFromPlayListApi = useCallback(
    async (playListId: number, playlistVideoId: number, videoId: number) => {
      await axios.delete(`/v2/playlists/${playListId}/videos/${playlistVideoId}`)
      revalidateApiForSelectPlayList(videoId)
    },
    [axios, revalidateApiForSelectPlayList],
  )

  return { addVideoToPlayListApi, deleteVideoFromPlayListApi, revalidateApiForPageUpdate }
}
