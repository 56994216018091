import { PlayList } from '~/types/api/playlist'

// 1度のリクエストで取得する動画の数
export const FETCH_LIMIT = 25

export const getPlayListBaseUrl = (playListId: number) => `/v2/playlists/${playListId}`

export const getPlayListFetchUrl = (
  playListId: number,
  playList: PlayList | null,
  params: string,
) => {
  const baseUrl = getPlayListBaseUrl(playListId)

  // 次の動画リストを取得するには、前回取得した最後のプレイリスト動画IDをクエリに付与する
  const addQueryParams = playList
    ? `&after=${playList.playlist.playlists_videos.slice(-1)[0].id}&${params}`
    : `&${params}`

  return `${baseUrl}?limit=${FETCH_LIMIT}${addQueryParams}`
}
