import { Box, Container } from '@mui/material'
import { Colors } from '~/libs/mui/theme'
import { AppFooterLeftContent, AppFooterRightContent } from './Content'

export function AppFooter(): JSX.Element {
  return (
    <Box component='footer' sx={{ backgroundColor: Colors.color0D0D0E, height: '100px' }}>
      <Container
        maxWidth='xl'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <AppFooterLeftContent />
        <AppFooterRightContent />
      </Container>
    </Box>
  )
}
