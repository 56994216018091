import { useMutation, useQuery } from '@apollo/client'
import { useCallback } from 'react'
import { AddLikeDocument, GetVideoLikeDocument, RemoveLikeDocument } from '~/__generated__/graphql'
import * as GA from '~/libs/ga'

export const useVideoLike = (id: string) => {
  const { data } = useQuery(GetVideoLikeDocument, {
    variables: { id },
  })
  const [addLike] = useMutation(AddLikeDocument, { variables: { videoId: id } })
  const [removeLike] = useMutation(RemoveLikeDocument, { variables: { videoId: id } })

  const onClickLike = useCallback(() => {
    if (data?.originalVideo?.currentUserHasLike) {
      // delete like
      removeLike({
        refetchQueries: [{ query: GetVideoLikeDocument, variables: { id } }],
      })
    } else {
      // add like
      GA.sendEvent({
        event: 'like',
        screen: 'movie_page',
        category: 'icon',
        action: 'movie_page_like',
        label: `${id}`,
      })
      addLike({
        refetchQueries: [{ query: GetVideoLikeDocument, variables: { id } }],
      })
    }
  }, [id, data, addLike, removeLike])

  return {
    currentUserHasLike: data?.originalVideo?.currentUserHasLike,
    likeNum: data?.originalVideo?.likeNum,
    onClickLike,
  }
}
