import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { FC, ReactNode, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import {
  Comment,
  CommentEdge,
  Reply,
  ReplyEdge,
  Video,
  VideoRecommendsEdge,
} from '~/__generated__/graphql'
import { accessTokenState } from '~/recoil/auth'
import browserEnv from '../env/browserEnv'
import { cursorConnection } from './cursorConnection'

const httpLink = createHttpLink({
  uri: `${browserEnv.NEXT_PUBLIC_API_HOST}/graphql`,
})

const ApolloProviderWrapper: FC<{ children?: ReactNode }> = ({ children }) => {
  const accessToken = useRecoilValue(accessTokenState)
  const client = useMemo(() => {
    const authLink = setContext(async (_, { headers, ...rest }) => {
      return {
        ...rest,
        headers: {
          ...headers,
          Authorization: accessToken === undefined ? undefined : `Bearer ${accessToken}`,
        },
      }
    })
    return new ApolloClient({
      link: ApolloLink.from([authLink, httpLink]),
      connectToDevTools: process.env.NODE_ENV !== 'production',
      cache: new InMemoryCache({
        typePolicies: {
          VideoCommentsConnection: cursorConnection<CommentEdge, Comment>(),
          CommentRepliesConnection: cursorConnection<ReplyEdge, Reply>(),
          VideoRecommendsConnection: cursorConnection<VideoRecommendsEdge, Video>(),
        },
      }),
    })
  }, [accessToken])
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloProviderWrapper
