import { useParams } from 'next/navigation'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import useSWR from 'swr'
import { useAddLocaleParams } from '~/features/i18n/hooks/useAddLocaleParams'
import { targetActionPlayListVideoInfoState } from '~/features/play_list/store'
import { useAxios } from '~/libs/axios'
import { availableAuthApiState } from '~/recoil/auth'
import { PlayListContent, PlayLists } from '~/types/api/playlist'
import { VideoPlayList, VideoPreview } from '~/types/api/videos'
import { getFetchPlaylistsUrl, getVideoPreviewInfoFetchUrl } from './urls'

//プレイリストの最大数は1000
const FETCH_MAX_LIMIT = 1000

export interface PlayListInfo {
  playListId: number
  playListVideoId: number | undefined
  title: string
  isRegisteredPlayList: boolean
}

interface PlayListRegistrationInfo {
  allPlayListInfo: PlayListInfo[]
  /** プレイリストID */
  playListId: number | undefined
  /** プレイリスト動画ID */
  playListVideoId: number | undefined
}

/**
 * アクション対象の動画に関わるプレイリスト情報をまとめるhooks
 */
export function usePlayListRegistrationInfo(): PlayListRegistrationInfo | 'loading' {
  const axios = useAxios()
  const params = useParams()
  const playListQueryId = params ? Number(params.id) : undefined
  const localeParams = useAddLocaleParams()
  const availableAuthApi = useRecoilValue(availableAuthApiState)
  const targetActionPlayListVideoInfo = useRecoilValue(targetActionPlayListVideoInfoState)
  const { data: playlists } = useSWR<PlayLists>(
    availableAuthApi ? getFetchPlaylistsUrl(localeParams) : null,
    (args) => axios.get(args).then((res) => res.data),
  )
  const { data: videoPreviewInfo } = useSWR<VideoPreview>(
    availableAuthApi && targetActionPlayListVideoInfo !== undefined
      ? getVideoPreviewInfoFetchUrl(targetActionPlayListVideoInfo.videoId, localeParams)
      : null,
    (args) => axios.get(args).then((res) => res.data),
  )

  return useMemo(() => {
    if (
      playlists === undefined ||
      videoPreviewInfo === undefined ||
      targetActionPlayListVideoInfo === undefined
    ) {
      return 'loading'
    }
    return createPlayListRegistrationInfo(
      playListQueryId,
      playlists.playlists,
      videoPreviewInfo.original.playlists,
      targetActionPlayListVideoInfo.videoId,
    )
  }, [playListQueryId, playlists, videoPreviewInfo, targetActionPlayListVideoInfo])
}

function createPlayListRegistrationInfo(
  playListQueryId: number | undefined,
  playlists: PlayListContent[], // プレイリスト一覧
  videoInfo: VideoPlayList[], //ビデオがどのプレイリストに登録されているかの情報
  videoId: number,
): PlayListRegistrationInfo {
  // ビデオが保存されているプレリストのIDリスト
  const registeredPlayListIdList = videoInfo.map((info) => info.id)
  const allPlayListInfo = playlists.map(({ id, title, playlists_videos }) => ({
    playListId: id,
    title,
    isRegisteredPlayList: registeredPlayListIdList.indexOf(id) !== -1,
    playListVideoId: playlists_videos.find((playlistsVideo) => {
      if (playlistsVideo.video.id === videoId) return playlistsVideo.id
    })?.id,
  }))
  const getPlayListVideoId = () => {
    const pagePlayList = playlists.find((playlist) => playlist.id === playListQueryId)
    const playListVideoId = pagePlayList?.playlists_videos.find(
      (playlistsVideo) => playlistsVideo.video.id === videoId,
    )?.id
    return playListVideoId
  }
  return {
    playListId: playListQueryId,
    allPlayListInfo,
    playListVideoId: getPlayListVideoId(),
  }
}
