import { Stack } from '@mui/material'
import { AspectRatio } from '@radix-ui/react-aspect-ratio'
import _ from 'lodash'
import dynamic from 'next/dynamic'
import { ComponentProps, memo } from 'react'
import { VideoPreviewModalActions } from '~/features/video/hooks/useVideoPreviewModalActions'
import { VideoPreviewModalInfo } from '~/features/video/hooks/useVideoPreviewModalInfo'
import { VideoButtonContent } from './VideoButtonContent'
import { VideoDetailContent } from './VideoDetailContent'

const VideoPlayerContentOrigin = dynamic(
  () => import('./VideoPlayerContent').then((modules) => modules.VideoPlayerContent),
  { ssr: false },
)

type ComponentPropsType = Readonly<ComponentProps<typeof VideoPlayerContentOrigin>>
const VideoPlayerContent = memo(
  VideoPlayerContentOrigin,
  (oldValue: ComponentPropsType, newValue: ComponentPropsType) =>
    Object.is(oldValue.onSetup, newValue.onSetup) &&
    Object.is(oldValue.sendTrailInsightsEvent, newValue.sendTrailInsightsEvent) &&
    Object.is(oldValue.src, newValue.src) &&
    // NOTE: プレイリストが更新されるとpreviewVideoが更新されてしまうため、メモ化が効かなくなり動画が再リロードされてしまうため、配列の比較はlodash.isEqualを使う
    _.isEqual(oldValue.vtts, newValue.vtts) &&
    _.isEqual(oldValue.subtitles, newValue.subtitles),
)

interface VideoPreviewModalContentProps {
  videoPreviewModalInfo: VideoPreviewModalInfo
  videoPreviewModalActions: Omit<VideoPreviewModalActions, 'onClose'>
}

export function VideoPreviewModalContent({
  videoPreviewModalInfo,
  videoPreviewModalActions,
}: VideoPreviewModalContentProps): JSX.Element {
  const {
    videoId,
    videoUrl,
    vvts,
    subtitles,
    title,
    duration,
    description,
    creator,
    willBePublishedAt,
  } = videoPreviewModalInfo
  const { onSetup, sendTrailInsightsEvent, onClickAddPlaylist, onClickVideoPlay } =
    videoPreviewModalActions
  const isUpComing = videoPreviewModalInfo.status === 'upcoming'
  return (
    <Stack spacing={2}>
      <AspectRatio ratio={16 / 9}>
        <VideoPlayerContent
          src={videoUrl}
          vtts={vvts}
          subtitles={subtitles}
          onSetup={onSetup}
          sendTrailInsightsEvent={sendTrailInsightsEvent}
        />
      </AspectRatio>
      <VideoDetailContent
        id={videoId}
        title={title}
        duration={duration}
        description={description}
        creator={creator}
        isUpComing={isUpComing}
        willBePublishedAt={willBePublishedAt}
      />
      <VideoButtonContent
        onClickAddPlaylist={() => onClickAddPlaylist(videoId)}
        onClickVideoPlay={() => onClickVideoPlay(videoId)}
        isUpComing={isUpComing}
        videoId={videoId}
      />
    </Stack>
  )
}
