import { ReactNode } from 'react'
import { LoadingBackdrop } from '~/components/LoadingBackdrop'
import { useAutoPlaySetting } from '../hooks/useAutoPlaySetting'

interface SettingPreparationProps {
  children: ReactNode
}

export function SettingPreparation({ children }: SettingPreparationProps): JSX.Element {
  const isSetupCompleted = useAutoPlaySetting()

  if (!isSetupCompleted) {
    return <LoadingBackdrop />
  }

  return <>{children}</>
}
