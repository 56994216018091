import { Avatar, Box, Button, Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import { SvgAsset } from '~/components/SvgAsset'
import { useVideoLike } from '~/hooks/useVideoLike'
import { Colors } from '~/libs/mui/theme'
import { useOnClickLikeButton } from './useOnClickLikeButton'

interface VideoInfoProps {
  id: number
  creator: {
    name?: string
    avatarUrl?: string
  }
  duration: string
  description: string
  showLike?: boolean
}

/**
 * クリエイター情報・動画内容・再生時間を表記するコンポーネント
 */
export function VideoInfo({
  id,
  creator,
  duration,
  description,
  showLike = false,
}: VideoInfoProps): JSX.Element {
  const { currentUserHasLike, likeNum, onClickLike } = useVideoLike(id.toString())
  const onClickLikeButton = useOnClickLikeButton(id.toString(), onClickLike)

  return (
    <Box>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' gap={1} justifyContent='center'>
          <Avatar src={creator.avatarUrl} />
          <Box>
            <Typography color={Colors.color5A5957} fontSize='10px'>
              <Trans i18nKey='creator' />
            </Typography>
            <Typography color={Colors.color91908F}>{creator.name}</Typography>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          justifyContent='center'
          color={Colors.color91908F}
        >
          {showLike && (
            <Button
              size='small'
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: currentUserHasLike ? Colors.yellow : Colors.color91908F,
                gap: 0.5,
                minWidth: 0,
                minHeight: 0,
                position: 'relative',
                left: '-5px', // button left padding の分を調整
              }}
              onClick={onClickLikeButton}
            >
              <SvgAsset asset='like' fontSize='small' />
              <Typography>{likeNum}</Typography>
            </Button>
          )}
          <Typography>{duration}</Typography>
        </Box>
      </Box>
      <Typography mt={1}>{description}</Typography>
    </Box>
  )
}
