import { createContext } from 'react'

interface Context {
  startPointElement: HTMLElement | null
  updateElement: (e: HTMLElement) => void
}

export const ActionStartingPointContext = createContext<Context>({
  startPointElement: null,
  updateElement: () => undefined,
})
