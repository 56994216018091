import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SvgAsset } from '~/components/SvgAsset'
import { PlayListVideoWhichNextActionProps } from './type'

const CloseArea = styled(Box)(() => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}))

type PlayListVideoWhichNextActionContentProps = Pick<
  PlayListVideoWhichNextActionProps,
  'onClose' | 'startAddVideoToPlayList' | 'startDeletePlayListVideo'
>

export function PlayListVideoWhichNextActionContent({
  onClose,
  startAddVideoToPlayList,
  startDeletePlayListVideo,
}: PlayListVideoWhichNextActionContentProps): JSX.Element {
  const { t } = useTranslation()
  return (
    <Box role='presentation'>
      <List sx={{ paddingTop: 5, paddingBottom: 4 }}>
        <ListItem disablePadding>
          <ListItemButton onClick={startAddVideoToPlayList}>
            <ListItemIcon>
              <SvgAsset asset='add_playlist' />
            </ListItemIcon>
            <ListItemText primary={t('add_to_playlist')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={startDeletePlayListVideo}>
            <ListItemIcon>
              <SvgAsset asset='trash_box' />
            </ListItemIcon>
            <ListItemText primary={t('remove_from_playlist')} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <CloseArea paddingTop={2.5} paddingBottom={3} textAlign='center' onClick={onClose}>
        <Typography>{t('close')}</Typography>
      </CloseArea>
    </Box>
  )
}
